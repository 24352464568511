import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AuthProvider, useAuth } from "react-oidc-context";
import { User, WebStorageStateStore } from 'oidc-client-ts';
const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
}
const oidcConfig = {
  authority: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_LicNE0ZNU',
  client_id: '4njvfdcl9c8cvfeo7buigipm1p',
  redirect_uri: 'https://appsync-poc.playground.koahealth.com',
  response_type: 'code',
  scope: 'openid profile email',
  post_logout_redirect_uri: 'https://appsync-poc.playground.koahealth.com/',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: onSigninCallback,
};
function App() {
  const auth = useAuth();

  if (auth.isLoading) {
      return <div>Loading...</div>;
  }

  if (auth.error) {
      return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
      return (
          <div>
              Hello {auth.user?.profile.sub}{" "}
              <button onClick={() => void auth.removeUser()}>
                  Log out
              </button>
          </div>
      );
  }

  return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider {...oidcConfig}>
    <App />
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
